import React, {useState, useRef} from "react";
import {Fade} from "react-reveal";
import "./StartupProject.scss";
import Flippy, {FrontSide, BackSide} from "react-flippy";

export default function ProjectCard({project, isDark}) {
  const ref = useRef();

  return (
    <Fade bottom duration={1000} distance="20px">
      <Flippy
        flipOnHover={false}
        flipOnClick={true}
        flipDirection="horizontal"
        ref={ref}
      >
        <FrontSide
          className={isDark ? "dark-card-mode frontside" : "frontside"}
        >
          <img src={project.image} className="project-image" alt="project" />
          <h1>{project.projectName}</h1>
          <p>{project.projectDesc}</p>
        </FrontSide>
        <BackSide className={isDark ? "dark-card-mode backside" : "backside"}>
          <h1>{project.projectName}</h1>
          <ul>
            {project.contributions
              ? project.contributions.map((item, i) => <li>{item}</li>)
              : null}
          </ul>
        </BackSide>
      </Flippy>
    </Fade>
  );
}
