import React from "react";
import {bigProjects} from "../../portfolio";
import StartupProject from "./StartupProject";
import "./WorkProjects.scss";

export default function WorkProjects() {

  return (
    <div>
      <h1 className="heading">Client Work</h1>
      <div className="projects-container">
      {bigProjects.projects.map((project, index) => (
        <StartupProject key={index} project={project} />
      ))}
    </div>
    </div>
  );
}
